.kk-dropdown-wrapper {
    display: inline-block;
    border: none;
    width:150px;
}

.kk-ml-moviearea {
    padding: 0;
}

.kk-ml-groupheader {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.kk-ml-groupheader-name {
    float: left;
    background: white;
    margin-left: 18px;
    padding: 0 8px;
}

.kk-ml-groupheader-count {
    float: right;
    padding: 0 8px;
    margin-right: 18px;
    background-color: white;
}

.kk-ml-groupheader-btn {
    float: right;
    background-color: white;
    cursor: pointer;
}

.kk-ml-groupheader-line {
    position: absolute;
    left: 6px;
    right: 6px; 
    top: 12px;
    z-index: -1;
    border-top: solid 1px #323232
}

.kk-ml-item {
    display: inline-block;
    vertical-align: top;
    margin-right:6px;
    margin-bottom:6px;
    overflow:hidden;
    background-color:black;
    cursor: pointer;
    position: relative;

    width: 185px;
    height: 265px;
}

.kk-ml-overlay {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.75);

    padding-top: 50px;
}

.kk-ml-overlay.clear {
    background-color: transparent;
}

.kk-ml-movietitle {
    height: 25px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    padding: 4px;
}

.kk-ml-togglebox {
    margin-top: 20px;
    color: rgb(240,240,240)
 }

 .kk-ml-morebtn {
    margin-right: 30px;
    margin-top:10px;
    color: rgb(200,200,200)
 }