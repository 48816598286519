.footer {
    padding-top:25px;
    padding-bottom:50px;
}

.foot-header {
    font-size:16px;   
    margin-bottom:5px; 
    padding:0px;
    color:black;
}

.foot-links a {
    text-decoration:none;
    color:inherit;
}