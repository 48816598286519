.togglebtn-caption {
    display: inline-block;
    margin-top: 5px;
    color: rgb(205,205,205);
}

.ab-light .togglebtn-caption {
    display: inline-block;
    margin-top: 5px;
    color: rgb(119, 119, 119);
}

.togglebtn-caption.active {
    display: inline-block;
    margin-top: 5px;
    color:white;
}

.ab-light .togglebtn-caption.active {
    display: inline-block;
    margin-top: 5px;
    color:black;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}
  
.btn-circle.btn-sm {
    width: 40px;
    height: 40px;
    padding: 0px 0px;
    font-size: 22px;
    line-height: 1.33;
    border-radius: 20px;
    border-width: 2px;
}
  
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 0px 0px;
    font-size: 25px;
    line-height: 1.33;
    border-radius: 25px;
    border-width: 2px;
}
  
.btn-circle.btn-xl {
    width: 60px;
    height: 60px;
    padding: 7px 13px;
    font-size: 28px;
    line-height: 1.33;
    border-radius: 35px;
}

.btn-brown, .btn-highlight.btn-brown {
    background-color:#825a2c;    
    border-color:#825a2c;      
    color:white;
}

.btn-brown.active {
    background-color:#825a2c;  
    border-color:white;  
    color:white;
}

.ab-light .btn-brown.active {
    background-color:#825a2c;  
    border-color:black;  
    color:white;
}

.btn-highlight.btn-pink, .btn-pink {
    background-color:#f472d0;
    border-color:#f472d0;
    color:white;
}

.ab-light .btn-pink.active {
    background-color:#f472d0;
    border-color:black;
    color:white;
}

.btn-pink.active {
    background-color:#f472d0;
    border-color:white;
    color:white;
}

.btn.btn-red, .btn-highlight.btn-red {
    background-color:#e51400;
    border-color:#e51400;
    color:white;
}

.ab-light .btn-red.active {
    background-color:#e51400;
    border-color:black;
    color:white;
}

.btn-red.active {
    background-color:#e51400;
    border-color:white;
    color:white;
}

.btn-green, .btn-highlight.btn-green {
    background-color:#008a00;
    border-color:#008a00;
    color:white;
}

.btn-green.active {
    background-color:#008a00;
    border-color:white;
    color:white;
}