.items > .item {
    padding-bottom:10px;
    padding-top:10px;
    border-top-color: rgb(187,187,187);
    border-top-style: solid;
    border-top-width:1px;
}

.items > .item.first {
    border-top-width:0px;
}