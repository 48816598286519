.kk-nf-feedarea {
    padding-top: 0;
}

.kk-nf-item {
    position: relative;
    vertical-align: bottom;
    display: inline-block;
    overflow:hidden;
    background-color:black;
    cursor: pointer;

    font-size: 14px;
    width: 185px;
    height: 305px;
}

.kk-nf-ghost {
    background-color: #F0F0F0;
    border-color: #aaa;
    border-width: 1px;
    border-style: solid;
    cursor: default;
}

.kk-nf-list-name {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    font-weight: 100;
    color: white;

    font-size: 16px;
    height: 30px;
    line-height: 30px;
    top: 242px;
    background-color: black;
}

.kk-nf-star {
    color: #d4d4d4;
}

.kk-nf-media-button {
    display: inline-block;
    color: #aaa;
    border-style: solid;
    border-color: #aaa;
    vertical-align: middle;

    border-width: 1px;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 20px;
    margin-left: 2px;
    height: 20px;
}

.kk-nf-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 5px;
}

.kk-nf-action {
    margin-top: 6px;
}

.kk-nf-action-icon {
    display: inline-block;
    text-align: center;

    width: 24px;
    height: 24px;
    line-height:24px;
    font-size:16px;
    margin: 0;
    border-radius: 4px;
}

.kk-nf-action-text {
    color: white;

    font-size: 12px;
    margin-left: 10px;
}

.kk-nf-actor {
    position: absolute;
    text-align: left;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;

    height: 40px;
    padding: 5px;
}

.kk-nf-actor-img {
    background: rgba(255, 255, 255, 0.50);

    width: 32px;
    height: 32px;
    border-radius: 100px;
    margin-right: 4px;
}

.kk-nf-actor-name {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    vertical-align: top;

    max-width: 65px;
    font-size: 12px;
    margin-left: 4px;
}

.kk-nf-actor-ts {
    color: white;
    opacity: 0.8;
    vertical-align: top;
    float: right;

    font-size: 10px;
}

.kk-nf-poster {
    width: 185px;
    height: 265px;
}

.kk-nf-welcome {
    position: absolute;
    text-align: center;
    font-weight: 100;
    color: white;

    font-size: 18px;
    left: 0;
    right: 0;
    bottom: 245px;
}

.kk-nf-user-img {
    position: absolute;
    left: 0;
    right: 0;

    height: 185px;
    top: 60px;
}

.kk-nf-user-name {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    font-weight: 100;
    color: white;

    font-size: 18px;
    top: 245px;
}

.kk-nf-newuser-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: left;

    height: 40px;
    padding: 5px;
}

.kk-nf-logo {
    float: left;

    width: 32px;
    height: 32px;
    margin-right: 4px;
}

.kk-nf-placeholder {
    overflow: visible;
}

.kk-nf-overlay {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.75);

    padding-top: 50px;
}

.kk-nf-overlay.clear {
    background-color: transparent;
}

.kk-nf-overlay-btn {
    border-radius: 8px;
    border-width: 2px;
    width: 155px;
    margin-left: 15px;
    height: 35px;
    line-height: 35px;
}

.kk-nf-comment {
    overflow:hidden;
    text-align: center;
    font-style: italic;
    text-overflow: ellipsis;
    color: white;

    font-size: 14px;
    line-height: 20px;

    position:absolute;
    top: 50px;
    left:10px;
    right:10px;
    height:200px;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
}

.kk-nf-listitem {
    overflow:hidden;
    vertical-align: central;
    text-align: center;
    font-style: italic;
    text-overflow: ellipsis;
    color: white;

    font-size: 14px;
    padding-top:25px;
    width: 155px;
    margin-top:0px;
    margin-left: 15px;
    height: 200px;
}

.kk-nf-movietitle {
    height: 25px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    padding: 4px;
}

.kk-nf-movietitle.dim {
    color: #d0d0d0
}

.kk-nf-togglebox {
   margin-top: 20px;
   color: rgb(240,240,240)
}

.kk-nf-morebtn {
   margin-right: 30px;
   margin-top:10px;
   color: rgb(200,200,200)
}

.kk-nf-button-icon {
    font-size: 24px;
    display: inline-block;
    margin-top: 4px;
    margin-left: 8px;
}

.kk-nf-button-text {
    display: inline-block;
    margin-left: 10px;
    height: 35px;
    line-height: 35px;
    vertical-align: top;
}

.kk-nf-ratee {
    display: inline-block;

    margin-top: 15px;

    font-size: 24px;
    color: #aaa;
}

.kk-nf-ratee:hover {
    color: white;
}

.gradient-top {
    /* webkit example */
    background: linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

.gradient-bot {
    /* webkit example */
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1.0));
}

.btn-green, .btn-highlight.btn-green {
    background-color:#008a00;
    border-color:#008a00;
    color:white;
}

.collected-true {
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    width: 155px;
    background-color: darkgrey;
    margin-left: 15px;
    height: 35px;
    line-height: 35px;
    vertical-align: top;
}

.collected-true:hover {
    border-color: white;
}

.collected-false {
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    width: 155px;
    margin-left: 15px;
    height: 35px;
    line-height: 35px;
    vertical-align: top;
}

.collected-false:hover {
    border-color: white;
}

.watchlist-false {
    margin-top: 10px;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-width: 2px;
    width: 155px;
    margin-left: 15px;
    height: 35px;
    line-height: 35px;
    vertical-align: top;
}

.watchlist-false:hover {
    border-color: white;
}
.watchlist-true {
    margin-top: 10px;
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    width: 155px;
    background-color: darkgrey;
    margin-left: 15px;
    height: 35px;
    line-height: 35px;
    vertical-align: top;
}

.watchlist-true:hover {
    border-color: white;
}

.filterItem:focus {
    background-color: inherit;
}

.filterItem.selected {
    background-color: #b5d1ff;
    font-weight: bold;
}

.pink_selected {
    background-color: #f472d0;
    color: white;
}

.white_selected {
    background-color: white;
    color: black;
}

.red_selected {
    background-color:#e51400;
    color:white;    
}

.green_selected {
    background-color:#008a00;
    color:white;    
}

.brown_selected {
    background-color:#825a2c;
    color:white;
}