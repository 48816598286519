.ab-dark .toggleBox {
    border-color: #fff;
}
.toggleBox {
    display: inline-block;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    border-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}
