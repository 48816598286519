.navbar {
    margin-bottom: 0px;
}

.navbar-dark.bg-dark {
    background-color: rgb(25,25,25) !important;
}

.navbar-nav > .nav-item > a {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

/* Add Windows8 colors to menu items */

.nav-item.c1 {
    border-left: solid 2px #fcd116
}

.nav-item.c1 > a:focus,
.nav-item.c1 > a:hover,
.nav-item.active_c1
 {
    background-color: #fcd116;
}

.nav-item.c2 {
    border-left: solid 2px #ff8c00
}

.nav-item.c2 > a:focus,
.nav-item.c2 > a:hover,
.nav-item.active_c2
 {
    background-color: #ff8c00;
}

.nav-item.c3 {
    border-left: solid 2px #e81123
}

.nav-item.c3 > a:focus,
.nav-item.c3 > a:hover,
.nav-item.active_c3 {
    background-color: #e81123;
}

.nav-item.c4 {
    border-left: solid 2px #ec008c
}

.nav-item.c4 > a:focus,
.nav-item.c4 > a:hover,
.nav-item.active_c4 {
    background-color: #ec008c;
}

.nav-item.c5 {
    border-left: solid 2px rgb(68, 35, 89)
}

.nav-item.c5 > a:focus,
.nav-item.c5 > a:hover,
.nav-item.active_c5 {
    background-color: rgb(68, 35, 89)
}

.nav-item.c9 {
    border-left: solid 2px #bbb;
}

.nav-item.c9 > a {
    color:white;
}

.nav-item.c9 > a:focus,
.nav-item.c9 > a:hover,
.nav-item.active_c9 > a,
.nav-item.active_c9 > a:hover,
.nav-item.active_c9 > a:focus {
    background-color: #eee;
    color:black;
}

/* Correctly color badges in menu colors */

li.c1 .badge {
    background-color: #fcd116;
}

li.c2 .badge {
    background-color: #ff8c00;
}

li.c3 .badge {
    background-color: #e81123;
}

li.c4 .badge {
    background-color: #ec008c;
}

li.c9 .badge {
    background-color: #e81123;
}


li.active_c1 .badge,
li.active_c2 .badge,
li.active_c3 .badge,
li.active_c4 .badge {
    color: rgb(60, 69, 79);
    background-color: #eaeaea;
}

.btn.btn-orange {
    background-color:#ff8c00;
    border-color:#ff8c00;
    color:white;
}