.movie-result-mobile {
    display: inline-block;
    width: 92px;
    height: 132px;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    vertical-align: top;
}

.movie-result-mobile img {
    width: 92px;
    height: 132px;
}