.c1 {
    color: #fcd116
}

.c2 {
    color: #ff8c00
}

.c3 {
    color: #e81123
}

.c4 {
    color: #ec008c
}

.c5 {
    color: rgb(68, 35, 89)
}