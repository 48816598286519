
html {
  font-size: 14px
}

.img-circle {
  border-radius: 50%;
}

/* Used as a divider div */
.rainbow {
  background: rgba(252,209,22,1);
  background: -moz-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(252,209,22,1)), color-stop(35%, rgba(255,140,0,1)), color-stop(68%, rgba(232,17,35,1)), color-stop(100%, rgba(236,0,140,1)));
  background: -webkit-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: -o-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: -ms-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: linear-gradient(to right, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcd116', endColorstr='#ec008c', GradientType=1 );
}
