.Select-control {
    background-color: inherit;
}

.Select-multi .Select-value {
    color:white !important;
}

.btn-opaque {
    border-color:rgb(51,51,51);
    color:rgb(51,51,51);
    background-color:rgba(0,0,0,0);
}

.ab-dark .btn-opaque {
    border-color:rgb(187,187,187);
    color:rgb(187,187,187);
    background-color:rgba(0,0,0,0);
}

.ab-dark .btn-opaque.active {
    background-color:rgb(187,187,187);
    color:black;
}

.btn-opaque.active {
    background-color:rgb(51,51,51);
    color:rgb(187,187,187);
}

.ab-dark input {
    background-color:transparent;
    color:rgb(187,187,187);
    padding:6px;
    margin-bottom: 3px;
    border:1px solid rgb(187,187,187);
}