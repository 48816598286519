
.movie-list-item {
    box-sizing: border-box;
    margin: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;

    border-top: 1px solid rgb(187,187,187);
}

.movie-list-item.first {
    border-top-style: none;
}

.movie-list-item.add {
    overflow: visible;
}

.movielistitem-desktop-image {
    height: 450px;
    margin-top: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.movielistitem-desktop-overlay {
    height: 450px;
    padding: 0px;
    margin: 0px;
    margin-right: -2px;
    background-size: cover;
    background: -moz-linear-gradient(top, rgba(25,25,25,1.0) 0%, rgba(25,25,25,1.0) 50%, rgba(25,25,25,0.5) 80%);
    background: -webkit-linear-gradient(top, rgba(25,25,25,1.0) 0%,rgba(25,25,25,1.0) 50%,rgba(25,25,25,0.5) 80%);
    background: linear-gradient(to bottom, rgba(25,25,25,1.0) 0%, rgba(25,25,25,0.0) 30%, rgba(25,25,25,0.0) 70%, rgba(25,25,25,1.0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=0 );
}

.movielistitem-desktop-comments {
    padding: 0px;
    margin: 0px;
    margin-right: -2px;
    background-size: cover;
    background: linear-gradient(to bottom, rgba(25,25,25,1.0) 0%, rgba(25,25,25,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=0 )
}
