.feedback { 
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 24px;
    background-color:#ff8c00;    
    color: white;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    padding-top: 10px; 
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
    cursor: pointer;
}
