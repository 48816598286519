body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight:400;
}

h1, h2, h3, h4, h5 {
  font-family: "Open Sans", sans-serif;
}

.ab-dark h1,
.ab-dark h2,
.ab-dark h3,
.ab-dark h4,
.ab-dark h5,
.ab-dark h6,
.ab-dark p {
    color: rgb(240,240,240);
}

.ab-dark small {
    color:rgb(200, 200, 200);
}

h1 {
  font-weight:500;
}

h3 {
  font-weight:300;
}

/* Used as a divider div */
.rainbow {
  background: rgba(252,209,22,1);
  background: -moz-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(252,209,22,1)), color-stop(35%, rgba(255,140,0,1)), color-stop(68%, rgba(232,17,35,1)), color-stop(100%, rgba(236,0,140,1)));
  background: -webkit-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: -o-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: -ms-linear-gradient(left, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  background: linear-gradient(to right, rgba(252,209,22,1) 0%, rgba(255,140,0,1) 35%, rgba(232,17,35,1) 68%, rgba(236,0,140,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcd116', endColorstr='#ec008c', GradientType=1 );
}
