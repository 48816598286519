.list-item 
{
    padding-bottom: 10px;
    padding-top: 5px;

    border-bottom-color: #bbb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.list-item.last {
    border-style: none;
}